.change-log-page {
  padding: 20px;
  align-items: center;
}

.change-log-page h2 {
  color: #FE78FD !important;
  text-align: center;
}

.entries-list {
  display: flex;
  flex-direction: column;
  padding: 20px; 
}

.entry-card {
  padding: 15px;
  margin: 10px 0;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);
  background-color: #68449c;
  border-radius: 8px; 
  display: block;  /* Ensures the card grows with its content */
  overflow: hidden; /* In case there are other overflowing elements, this will contain them */
}

.entry-card p {
  margin: 0;
  padding: 0;
  color: White !important;
}

/* Styles for date-time and entry-text rectangles */
.entry-card .entry-text {
  border: 2px solid #FE78FD; /* Using the color of ChangeLog text for the border */
  border-radius: 4px; /* Giving a bit of rounded corners */
  padding: 6px; /* Adjusting padding to account for the border width */
  margin: 5px 0; /* Margin to separate from other elements */
  color: White; /* Text color */
  overflow: hidden;  /* In case there are other overflowing elements, this will contain them */
 
}

.entry-card video {
  max-width: 50%;  /* Ensure the video doesn't exceed the width of its container */
  width: auto;      /* Allow the video to adjust its width according to its intrinsic width */
  height: auto;     /* Allow the video to adjust its height according to its intrinsic height */
}

.date-time {  
  font-size: 0.85em; /* Reducing the font size */
  margin-bottom: 6px; /* Space between date-time and title */
  border: none; /* Removing the border */
}

.entry-card-link {
  text-decoration: none;
}

.entry-card-link:hover .entry-card {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  color: #040317 !important;
  transform: scale(1.02); /* slightly increase size */
  transition: all 0.3s ease; /* smooth transition for all changes */
}

body .ql-container .ql-size-small {
  font-size: 0.75em;
}

body .ql-container .ql-size-huge {
  font-size: 2em;
}

/* Container for the button */
.buttoncontainer {
  display: flex;
  justify-content: center; /* Horizontally center the button */
}

.backButton {
  background: none;       /* No background color */
  border: none;           /* Remove the border */
  padding: 0;             /* Reset padding */
  margin: 0;              /* Reset margin */
  outline: none;          /* Remove outline */
  cursor: pointer;        /* Show pointer cursor on hover */
  appearance: none;       /* Remove browser specific styling */
  -webkit-appearance: none;
  -moz-appearance: none;
  display: inline-flex;   /* Use flex to center image if needed */
  justify-content: center;
  align-items: center;
  overflow: hidden;       /* In case the image is larger */
}

.backButton img {
  display: block;
  width: auto;            /* Maintain image aspect ratio */
  height: 100%;           /* Image takes full height of button */
}


.addButton {
  display: block;
  margin-left: 0;
  margin-right: auto;
}

.backButton:hover {
  background: none;       /* Ensure no background change on hover */
  box-shadow: none;       /* Remove any box-shadow */
  transform: none;        /* Ensure it doesn't move/scale */
}

.backButton:focus {
  outline: none;          /* Remove focus outline, but be cautious about accessibility */
  box-shadow: none;       /* Remove any box-shadow on focus */
}

.change-log-page h2 {
  color: #FE78FD !important;
  text-align: center;
  font-size: 2.5em; /* Adjust this value as per your need */
}


.entry-card img {
  max-width: 50%; /* you can adjust this value */
  max-height: 50%; /* you can adjust this value */
  display: inline-block; /* makes images go side-by-side if they fit */
}