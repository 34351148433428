body {
    background:#090931;
    color: #CCCCCC;
    font-family: Arial, sans-serif;
}
.container {
    background-color: rgb(9,9,49);
    margin: 0 auto;
    max-width: 800px;
    padding: 20px;
    border: 1px solid #FFFFFF;
}
.title {
    margin: 0 auto;
    max-width: 800px;
    padding: 20px;
}
.img {
    max-width: 100%;
}
h2, h3 {
    color: #FFFFFF;
}
.tip-box {
    border: 1px solid #FE78FD;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}