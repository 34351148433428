.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 5vh;
    padding: 2rem;
}

.title {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.inputGroup {
    margin-bottom: 1rem;
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
}

.label {
    margin-bottom: 0.5rem;
}

.input, .textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.textarea {
    resize: vertical;
    min-height: 100px;
}

.button {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #eee;
}

.react-quill {
    max-height: 300px; /* Adjust as necessary */
    overflow-y: auto; /* Allows for scrolling if the content exceeds the editor's max height */
}

.quill-editor {
    height: 400px;
}
/* To target the label of the dropdown picker */
.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    color: white !important;
}

/* To target the dropdown picker items */
.ql-snow .ql-picker.ql-expanded .ql-picker-item {
    color: white !important;
}

/* To target the dropdown arrow (the pseudo-element) */
.ql-snow .ql-picker.ql-expanded .ql-picker-label::before {
    border-top-color: white !important;
}

/* Container for the button */
.buttoncontainer {
    display: flex;
    justify-content: center; /* Horizontally center the button */
  }
  
  .backButton {
    background: none;       /* No background color */
    border: none;           /* Remove the border */
    padding: 0;             /* Reset padding */
    margin: 0;              /* Reset margin */
    outline: none;          /* Remove outline */
    cursor: pointer;        /* Show pointer cursor on hover */
    appearance: none;       /* Remove browser specific styling */
    -webkit-appearance: none;
    -moz-appearance: none;
    display: inline-flex;   /* Use flex to center image if needed */
    justify-content: center;
    align-items: center;
    overflow: hidden;       /* In case the image is larger */
  }
  
  .backButton img {
    display: block;
    width: auto;            /* Maintain image aspect ratio */
    height: 100%;           /* Image takes full height of button */
  }
  
  
  .addButton {
    display: block;
    margin-left: 0;
    margin-right: auto;
  }
  
  .backButton:hover {
    background: none;       /* Ensure no background change on hover */
    box-shadow: none;       /* Remove any box-shadow */
    transform: none;        /* Ensure it doesn't move/scale */
  }
  
  .backButton:focus {
    outline: none;          /* Remove focus outline, but be cautious about accessibility */
    box-shadow: none;       /* Remove any box-shadow on focus */
  }

.ql-snow .ql-set-bg-color svg {
  fill: #000;  /* Black color for the icon */
}

.ql-snow .ql-set-bg-color::before {
  content: '';
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><line x1="7" x2="7" y1="4" y2="14" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></line><line x1="11" x2="11" y1="4" y2="14" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></line></svg>');
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;
  width: 18px;
  height: 18px;
}