.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.content {
    margin-bottom: 20px;
}
.actions {
    display: flex;
    justify-content: space-between;
}
.submit, .close {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.submit {
    background-color: #007bff;
    color: white;
}
.close {
    background-color: #6c757d;
    color: white;
}
