
.dashboard-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #090931;
    color: white;
}

#logoutButton { 
    font-size: 20px;
    margin-top: 20px;
}

#discordButton{ 
    font-size: 20px;
    margin-top: 1px;
    margin-bottom: 1px;
}

#helpButton2,#helpButton3,#helpButton4{
    font-size: 14px;
    margin-top: 1px;
    margin-bottom: 1px;
    margin: 1px;
}

.theinvisible-button {
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    color: transparent;
}


#testsubscriptionButton {
    font-size: 24px; /* or whatever size you prefer */
    background-color: forestgreen;
    color: white;
}

.flex-col {
    flex-direction: column;
}
.custom-link {
    color: #FE78FD !important;
    transition: color 0.3s ease-in-out;
    text-decoration: none;
}

.custom-link:focus, .custom-link:hover {
    color: white !important;
    transition: color 0.3s ease-in-out;
    text-decoration: none;
}