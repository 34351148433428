/* PaymentHistory.module.css */
.paymentHistory {
  font-family: 'Arial', sans-serif;
  padding: 20px;
}

.paymentTable {
  width: 100%;
  border-collapse: collapse;
}

.paymentTable th,
.paymentTable td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.paymentTable th {
  color: #000; /* Black color for header cells */
  background-color: #f4f4f4;
}

.paymentTable td {
  color: #fff; /* White color for data cells */
}

.tableResponsive {
  overflow-x: auto; /* Enables horizontal scrolling */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}
