
.changelogcontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.changelogbutton {
  background-color: white;
  color: black;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  transition: background-color 0.3s ease;
  border: 1px solid #FE78FD;
}

.logo {
  width: 100%;
  max-width: 1200px; /* Adjust max-width for desktop */
  height: auto;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .logo {
    max-width: 400px; /* Adjust as needed for smaller screens */
  }
}