.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    width: 50%;
    max-width: 500px;
    color: #333;
    font-family: 'Roboto', sans-serif;
    border: 1px solid #e0e0e0;
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    background-color: #f4f5f7;
    border-bottom: 1px solid #e0e0e0;
}

.closeButton {
    border: none;
    background: none;
    font-size: 1.5rem;
    color: #333;
    cursor: pointer;
    padding: 0;
    margin-left: auto;
}

.modalContent {
    padding: 24px;
    font-size: 16px;
}

.modalFooter {
    display: flex;
    justify-content: flex-end;
    padding: 16px 24px;
    border-top: 1px solid #e0e0e0;
    background-color: #f4f5f7;
}

.actionButton {
    border: none;
    background-color: #6200ee;
    color: white;
    text-transform: uppercase;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    margin-left: 8px;
    transition: background-color 0.3s;
}

.actionButton:hover {
    background-color: #3700b3;
}

/* Styles for other text and elements within the modal */
.invoiceDetails p {
    color: #333;
    margin-bottom: 8px;
}

/* Ensure the close button is visually integrated into the modal header */
.closeButton {
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #333;
    cursor: pointer;
    padding: 0;
}

/* Adjustments for the Invoice Details to match the style in the screenshot */
.invoiceDetails {
    margin-top: 20px;
    color: #333;
    line-height: 1.5;
}

/* Additional styles for headings if needed */
.modal h2 {
    color: #333;
}

/* Additional styles for paragraphs if needed */
.modal p {
    color: #333;
}

/* Print-specific styles */
@media print {
    .modalBackdrop {
        background-color: transparent;
    }
    .modal {
        box-shadow: none;
        margin-top: 0;
        border: none;
        max-width: 100%;
    }
    .modalHeader, .modalFooter, .closeButton, .actionButton {
        display: none;
    }
    .modalContent {
        padding: 0;
    }
}
