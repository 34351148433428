.checkout {
  background: #090931;
  color: #fff;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.left-side {
  display: flex;
  flex-direction: column;
}

.right-side {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Horizontal Centering */
  align-items: center; /* Vertical Centering */
}

.tiers-container, .checkout-form-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.tier-card {
  width: 100px;
  height: 175px;
  margin: 1rem;
  padding: 1rem;
  box-shadow: 0 8px 16px 0 rgba(116, 65, 65, 0.35);
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center; 
  word-wrap: break-word;
  color: #000; /* Add black color for text */
}

.checkout-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 60%;
}

.checkout-form {
  width: 60%;
  min-width: 300px;  /* Ensure form has minimum width */
  padding: 2rem;
  background-color: white;
  border-radius: 10px;
  margin-right: 2rem;  /* Add some space between form and tier card */
}

.checkout .tier-card {
  width: 30%; 
  min-width: 100px;
}

.form-row {
  margin-bottom: 1.5rem;
}

#card-element {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 10px;
}

.card-errors {
  color: red;
  margin-top: 10px;
}

#submit {
  background-color: #32325d;
  color: white;
  padding: 10px;
  border: none;
  width: 100%;
  margin-top: 1rem;
  border-radius: 4px;
}

#submit:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

label {
  font-size: 14px;
  line-height: 24px;
  color: #424770;
  display: block;
  margin-bottom: 5px;
}

.fullscreen-spinner {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0,0,0,0.5);  /* semi-transparent background */
  z-index: 9999;  /* make sure the spinner is on top of everything else */
}

.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 100px;  /* adjust to match the width of your pay button */
  height: 100px;  /* adjust to match the height of your pay button */
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.coupon-message-label {
  display: block;
  color: #333;  /* Any color of your preference */
  font-size: 18px;  /* Adjust as per your design */
  margin-bottom: 5px;  /* Small margin for space below the label */
}

.coupon-message-space {
  display: block;
  height: 20px; /* You can adjust this height for the desired space */
}

.invalid-coupon {
  color: red;
}

.valid-coupon {
  color: green;
}

.terms-of-service-agreement {
  color: Gray;
}
