.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent backdrop */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's above other elements */
}

.modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    max-width: 400px; /* Maximum width of the modal */
    width: 90%; /* Responsive width */
    z-index: 1001; /* Above the backdrop */
}

.content {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.actions {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.submit, .close {
    background-color: #007bff; /* Bootstrap primary color */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.close {
    background-color: #dc3545; /* Bootstrap danger color */
}

.submit:hover, .close:hover {
    background-color: #0056b3; /* Darken on hover */
}

label {
    margin-right: 10px;
}

input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
}
