body {
    background: #090931;
    color: #797979;
    font-family: Arial, sans-serif;
}

.container {
    background-color:#090931;
    margin: 0 auto;
    max-width: 800px;
    padding: 20px;
    border: 1px solid #7a7575;
}

.title {
    margin: 0 auto;
    justify-content: center;
    max-width: 800px;
    padding: 20px;
}

.img {
    max-width: 100%;
}

h2, h3 {
    color: white;
}

.tip-box {
    border: 1px solid #FE78FD;
    padding: 10px;
    background-color: #090931;
    margin-bottom: 20px;
}

a {
    color: rgb(110, 110, 110);
}

a:link {
    color: #ED73F2;
}

a:visited {
    color: #ED73F2;
}

a:hover {
    color: #fff
}