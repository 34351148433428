.dashboard-page {
    align-items: center;
    background-color: #090931;
    color: #fff;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: flex-start !important; /* Override any other justify-content property */
    padding-top: 75px;
}



/* Media queries for smaller screens */
@media (max-width: 768px) {
    .dashboard-page {
        padding-top: 10px; /* Start with a smaller padding on smaller screens */
    }

    #testsubscriptionButton {
        font-size: 18px; /* Adjust font size for smaller screens if needed */
    }
}

  
#logoutButton { 
    font-size: 20px;
    margin-top: 20px;
}

#discordButton{ 
    font-size: 20px;
    margin-top: 1px;
    margin-bottom: 1px;
}

#helpButton2,#helpButton3,#helpButton4{
    font-size: 14px;
    margin-top: 1px;
    margin-bottom: 1px;
    margin: 1px;
}

.theinvisible-button {
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    color: transparent;
}

#testsubscriptionButton {
    font-size: 24px; /* or whatever size you prefer */
    background-color: forestgreen;
    color: white;
}

.flex-col {
    flex-direction: column;
}
.custom-link {
    color: #FE78FD !important;
    transition: color 0.3s ease-in-out;
    text-decoration: none;
}

.custom-link:focus, .custom-link:hover {
    color: white !important;
    transition: color 0.3s ease-in-out;
    text-decoration: none;
}

.buttons-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.or-text {
    color: white;
    margin: 0 10px; /* Adjust the spacing around the 'or' text as needed */
    font-size: 20px; /* Adjust font size as needed */
}