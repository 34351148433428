.error-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #32325d;
}

.error-message {
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 32px;
    border-radius: 8px;
    background-color: #52527d;
    max-width: 500px;
}

.error-message h1 {
    font-size: 24px;
    color: #333;
    margin-bottom: 16px;
}

.error-message p {
    font-size: 18px;
    color: white;
}
