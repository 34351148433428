body {
    background: radial-gradient(circle, white, white);
    color: #CCCCCC;
    justify-content: center;
    font-family: Arial, sans-serif;
}
.container {
    background-color: #090931;
    margin: 0 auto;
    max-width: 800px;
    padding: 20px;
    border: 1px solid #FFFFFF;
}
.title {
    margin: 0 auto;
    max-width: 800px;
    padding: 20px;
    color: white;
}
.img {
    max-width: 100%;
}
h1,h2, h3, p {
    color: #FFFFFF;
}
.tip-box {
    border: 1px solid #FE78FD;
    padding: 10px;
    background-color: #090931;
    margin-bottom: 20px;
}
a {
     color: pink; /* Color for normal and visited links */
}

a:visited {
    color: rgb(255, 0, 0); /* Color for visited links */
}

a:hover {
    color: #FE78FD; /* Color for hover state */
}