h1 {
  margin: 0;
  padding: 0;
  background-color: #090931;
  color: #fff;
}

.payment-page {
  background: #090931;
  color: #fff;
  min-height: 100vh;
}

.tiers-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.tier-status {
  text-align: center;
}

.tier-card {
  position: relative;
  min-width: 275px;
  min-height: 390px;
  margin: 1rem;
  padding: 1rem;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.35);
  border-radius: 35px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  text-align: left;
  overflow-wrap: break-word;
  padding-bottom: 10px;
  color: #fff;
}

.tier-card h3 {
  position: relative;
  background-color: #48247f;
  color: #fff;
  border: 1px solid #000;
  padding: 0.25rem 0.5rem;
  width: fit-content;
  margin-bottom: 1rem;
}

.tier-card.selected {
  background-color: #c0d6e4 !important;
  cursor: default;
  pointer-events: none;
}

.selected-tier-name {
  background-color: #c0d6e4 !important;
  color: #000;
}

.tier-card p,
.tier-card .attribute-name {
  color: #fff; /* Add this rule */
}

.tier-card p {
  margin-bottom: 0.5rem;
}

.tier-card p:last-child {
  margin-bottom: 0;
}

.attribute-name {
  font-weight: bold;
}

.fullscreen-spinner {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #0909af;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.old-price {
  text-decoration: line-through;
}