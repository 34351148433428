.backgroundImage {
    background-size: cover;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .content-wrapper {
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .confirmation-card {
    width: 50%;
    text-align: center;
    border: 1px solid black;
    border-radius: 10px;
    padding: 20px;
    background-color: white;
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
  }
  
  #goToDashboardButton {
    font-size: 20px;
    margin-top: 20px;
  }
  @media (min-width: 600px) {
    .confirmation-card {
        width: 50%;
        text-align: center;
        border: 1px solid black;
        border-radius: 10px;
        padding: 20px;
        background-color: white;
        word-wrap: break-word;
        overflow-wrap: break-word;
        hyphens: auto;
      }
  }
  