.credit-package-selection-page {
    background: #090931;
    color: #fff;
    min-height: 100vh;
}

.package-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.package-card {
    width: 200px;
    height: auto;
    margin: 1rem;
    padding: 1rem;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.35);
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    text-align: left;
    word-wrap: break-word;
}

.package-card h3 {
    margin-bottom: 0.5rem;
    background-color: #48247f; /* Updated background color */
    color: #fff; /* Updated text color to white */
    border: 1px solid #000;
    padding: 0.25rem 0.5rem;
    width: fit-content;
}

.package-card p {
    margin-bottom: 0.5rem;
}

.attribute-name {
    font-weight: bold;
}
