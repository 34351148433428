.body {
    background: radial-gradient(circle, #090931, #19083D);
    color: #CCCCCC;
    font-family: Arial, sans-serif;
  }
  
  .container {
    background-color: rgb(9,9,49);
    margin: 0 auto;
    max-width: 800px;
    padding: 20px;
    border: 1px solid #FFFFFF;
  }
  
  .title {
    margin: 0 auto;
    max-width: 800px;
    padding: 20px;
  }
  
  .img {
    max-width: 100%;
  }
  
  .h2, .h3 {
    color: #FFFFFF;
  }
  
  .tipBox {
    border: 1px solid #FE78FD;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .thumbnail {
    width: 100px;
    height: 100px;
    margin: 10px;
    cursor: pointer;
  }
  
  .modal {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.7);
  }
  
  .modalContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 600px;
    width: auto;
    height: auto;
  }
  
  @media only screen and (max-width: 600px) {
    .modalContent {
      max-height: 80vh;
    }
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 30px;
    cursor: pointer;
  }
  