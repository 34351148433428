.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 2rem;
}

.title {
    font-size: 3rem;
    margin-bottom: 1rem;
}

.date {
    margin-bottom: 1rem;
    color: #777;
}

.text {
    margin-bottom: 1rem;
}

.buttonGroup {
    display: flex;
    gap: 1rem;
}
/* Container for the button */
.buttoncontainer {
    display: flex;
    justify-content: center; /* Horizontally center the button */
  }
  
  .backButton {
    background: none;       /* No background color */
    border: none;           /* Remove the border */
    padding: 0;             /* Reset padding */
    margin: 0;              /* Reset margin */
    outline: none;          /* Remove outline */
    cursor: pointer;        /* Show pointer cursor on hover */
    appearance: none;       /* Remove browser specific styling */
    -webkit-appearance: none;
    -moz-appearance: none;
    display: inline-flex;   /* Use flex to center image if needed */
    justify-content: center;
    align-items: center;
    overflow: hidden;       /* In case the image is larger */
  }
  
  .backButton img {
    display: block;
    width: auto;            /* Maintain image aspect ratio */
    height: 100%;           /* Image takes full height of button */
  }
  
  
  .addButton {
    display: block;
    margin-left: 0;
    margin-right: auto;
  }
  
  .backButton:hover {
    background: none;       /* Ensure no background change on hover */
    box-shadow: none;       /* Remove any box-shadow */
    transform: none;        /* Ensure it doesn't move/scale */
  }
  
  .backButton:focus {
    outline: none;          /* Remove focus outline, but be cautious about accessibility */
    box-shadow: none;       /* Remove any box-shadow on focus */
  }
  